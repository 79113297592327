import React from "react";
import clsx from "clsx";
import { SEO, MetaProps } from "~components/templates/seo";
import { Header, Props as HeaderProps } from "~components/templates/header";
import { Footer } from "~components/templates/footer";
import "~styles/root.scss";
import * as styles from "./index.module.scss";

type Props = {
  meta?: MetaProps;
  headerProps?: HeaderProps;
  open?: boolean;
  paddingPattern?: "none" | "fit-header" | "larger-than-header";
};

export const PageLayout: React.FC<Props> = (props) => {
  const { paddingPattern, children, meta, headerProps } = props;

  if (paddingPattern === "larger-than-header") {
    return (
      <div>
        <SEO {...meta} />
        <Header {...headerProps} />
        <main className={styles.Layout_Body}>
          <div className={clsx(styles.PageLayout, styles.PageLayout__large)}>
            {children}
          </div>
        </main>
        <Footer lang={meta?.lang} />
      </div>
    );
  }

  if (paddingPattern === "none") {
    return (
      <div>
        <SEO {...meta} />
        <Header {...headerProps} />
        <main className={styles.Layout_Body}>
          <div className={clsx(styles.PageLayout, styles.PageLayout__none)}>
            {children}
          </div>
        </main>
        <Footer lang={meta?.lang} />
      </div>
    );
  }

  return (
    <div>
      <SEO {...meta} />
      <Header {...headerProps} />
      <main className={styles.Layout_Body}>
        <div className={clsx(styles.PageLayout, styles.PageLayout__normal)}>
          {children}
        </div>
      </main>
      <Footer lang={meta?.lang} />
    </div>
  );
};
