import React, { FC, PropsWithChildren } from "react";
import { LanguageContextProvider } from "./LanguageContext";
import { ModalContextProvider } from "./ModalContext";

export function withContext<P>(Child: FC<P>) {
  const Component = (props: PropsWithChildren<P>) => {
    return (
      <LanguageContextProvider>
        <ModalContextProvider>
          <Child {...props} />
        </ModalContextProvider>
      </LanguageContextProvider>
    );
  };
  return Component;
}
