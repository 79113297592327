import React, { ChangeEvent } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import * as styles from "./index.module.scss";

export type Props = {
  defaultIndustry?: string;
  defaultPlan?: string;
};

export const VoiceFilter = ({ defaultIndustry, defaultPlan }: Props) => {
  const data: GatsbyAssessmentVoiceFilterComponentQuery = useStaticQuery(
    graphql`
      query AssessmentVoiceFilterComponent {
        allContentfulVoiceIndustry {
          nodes {
            slug
            name
          }
        }
        allContentfulVoicePlan {
          nodes {
            slug
            name
          }
        }
      }
    `,
  );

  const { allContentfulVoiceIndustry, allContentfulVoicePlan } = data;

  const handleSelectIndustry = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    if (defaultPlan === undefined) {
      if (value === "") {
        navigate("/assessment/voice");
      } else {
        navigate(`/assessment/industries/${value}/voice`);
      }
    } else {
      if (value === "") {
        navigate(`/assessment/plans/${defaultPlan}/voice`);
      } else {
        navigate(`/assessment/voice?industry=${value}&plan=${defaultPlan}`);
      }
    }
  };

  const handleSelectPlan = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    if (defaultIndustry === undefined) {
      if (value === "") {
        navigate("/assessment/voice");
      } else {
        navigate(`/assessment/plans/${value}/voice`);
      }
    } else {
      if (value === "") {
        navigate(`/assessment/industries/${defaultIndustry}/voice`);
      } else {
        navigate(`/assessment/voice?industry=${defaultIndustry}&plan=${value}`);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.arrow}>
        <select
          name="industry-filter"
          id="industry-filter"
          className={styles.selector}
          defaultValue={defaultIndustry ?? ""}
          onChange={handleSelectIndustry}
        >
          <option value="">業界から選ぶ</option>
          {allContentfulVoiceIndustry.nodes.map((node) => {
            return (
              <option key={node.slug} value={node.slug}>
                {node.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className={styles.arrow}>
        <select
          name="plan-filter"
          id="plan-filter"
          className={styles.selector}
          defaultValue={defaultPlan ?? ""}
          onChange={handleSelectPlan}
        >
          <option value="">診断プランから選ぶ</option>
          {allContentfulVoicePlan.nodes.map((node) => {
            return (
              <option key={node.slug} value={node.slug}>
                {node.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
