import React from "react";

import { VoiceData, VoiceList } from "~components/utils/voice-list";
import { VoiceFilter, Props as VoiceFilterProps } from "../voice-filter";

type Props = {
  data: VoiceData[];
  slugs?: string[];
} & VoiceFilterProps;

export const VoiceListWithFilter = (props: Props) => {
  const { defaultIndustry, defaultPlan, slugs, data } = props;

  return (
    <>
      <VoiceFilter
        defaultIndustry={defaultIndustry}
        defaultPlan={defaultPlan}
      />
      <div className="h-4" />
      <VoiceList data={data} slugs={slugs} />
    </>
  );
};
